import { SubNavBarContextProvider } from 'context/SubNavBarContext'
import { ReviewsContextProvider } from 'context/ReviewsContext'
import dynamic from 'next/dynamic'
import React from 'react'
import {
  TemplateAboutUsPageComponentsCollection,
  TemplateAboutUsPageComponentsItem,
  TemplateJoinUsPageComponentsCollection,
  TemplateJoinUsPageComponentsItem,
  TemplateWhyBookWithForaPageComponentsCollection,
  TemplateWhyBookWithForaPageComponentsItem,
  TemplateCareersPageComponentsCollection,
  TemplateCareersPageComponentsItem,
  TemplateDestinationsCollection,
  TemplatePartnersPageComponentsCollection,
  TemplatePartnersPageComponentsItem,
  TemplateHomePageComponentsCollection,
  TemplateHomePageComponentsItem,
  TemplateNewsletterPageComponentsCollection,
  TemplateNewsletterPageComponentsItem,
  TemplateDestinationsPageComponentsItem,
  TemplateContactUsPageComponentsCollection,
  TemplateContactUsPageComponentsItem,
  TemplateBookingPageComponentsCollection,
  TemplateBookingPageComponentsItem,
  TemplateForaReservePageComponentsCollection,
  TemplateForaReservePageComponentsItem,
  TemplateReviewsPageComponentsCollection,
  TemplateReviewsPageComponentsItem,
  TemplateForaXPageComponentsCollection,
  TemplateForaXPageComponentsItem,
} from 'types/generated/contentful-types'
import { StickyHeaderContextProvider } from 'context/StickyHeaderContext'

// create a dynamic registry of renderable page blocks
// This allows us to fetch the necessary components for a
// given page without having to fetch all existing components
export type pageBlockRegistryProps = {
  [key: string]: any
}

const pageBlockRegistry: pageBlockRegistryProps = {
  AdvisorPlanTable: dynamic(
    () => import('@/components/pageBlocks/AdvisorPlanTable')
  ),
  AdvisorReviews: dynamic(
    () => import('@/components/pageBlocks/AdvisorReviews')
  ),
  AnimatedTiles: dynamic(() => import('@/components/pageBlocks/AnimatedTiles')),
  ButtonCta: dynamic(() => import('@/components/ui/ComponentButtonCta')),
  CarouselSection: dynamic(
    () => import('@/components/pageBlocks/CarouselSection')
  ),
  CommissionCarousel: dynamic(
    () => import('@/components/pageBlocks/CommissionCarousel')
  ),
  ComparisonTable: dynamic(
    () => import('@/components/pageBlocks/CompareTable')
  ),
  CtaBanner: dynamic(() => import('@/components/pageBlocks/CtaBanner')),
  DataBanner: dynamic(() => import('@/components/pageBlocks/DataBanner')),
  FaqsQuestionGroups: dynamic(
    () => import('@/components/pageBlocks/QuestionGroup')
  ),
  FeaturedAdvisors: dynamic(
    () => import('@/components/pageBlocks/FeaturedAdvisors')
  ),
  FeaturedArticles: dynamic(
    () => import('@/components/pageBlocks/FeaturedArticles')
  ),
  FeaturedGuides: dynamic(
    () => import('@/components/pageBlocks/FeaturedGuides')
  ),
  FeaturedHotels: dynamic(
    () => import('@/components/pageBlocks/FeaturedHotels')
  ),
  FeaturedReviews: dynamic(
    () => import('@/components/pageBlocks/FeaturedReviews')
  ),
  ForaLogo: dynamic(() => import('@/components/pageBlocks/ForaLogo')),
  Form: dynamic(() => import('@/components/pageBlocks/Forms')),
  HeaderBlock: dynamic(() => import('@/components/pageBlocks/HeaderBlock')),
  HeroBlock: dynamic(() => import('@/components/pageBlocks/HeroBlock')),
  HeroCentered: dynamic(
    () => import('@/components/pageBlocks/HeaderBlockWithImage')
  ),
  HeroFullWidth: dynamic(() => import('@/components/pageBlocks/FullWidthHero')),
  ImageSideBySide: dynamic(
    () => import('@/components/pageBlocks/ImageSideBySide')
  ),
  ImageGalleryCarousel: dynamic(
    () => import('@/components/pageBlocks/ImageGalleryCarousel')
  ),
  InfoGrid: dynamic(() => import('@/components/pageBlocks/InfoGrid')),
  LearnMoreBlock: dynamic(
    () => import('@/components/pageBlocks/LearnMoreBlock')
  ),
  PartnerBanner: dynamic(() => import('@/components/itineraries/Partners')),
  PartnerGrid: dynamic(() => import('@/components/pageBlocks/PartnerGrid')),
  PerksList: dynamic(() => import('@/components/pageBlocks/PerksList')),
  PictureSectionBanner: dynamic(
    () => import('@/components/pageBlocks/PictureSectionBanner')
  ),
  PressBanner: dynamic(() => import('@/components/pageBlocks/PressBanner')),
  Reviews: dynamic(() => import('@/components/pageBlocks/Reviews')),
  SingleUse: dynamic(() => import('@/components/pageBlocks/SingleUse')),
  Spacer: dynamic(() => import('@/components/pageBlocks/SpacerComponent')),
  StickyHeader: dynamic(() => import('@/components/pageBlocks/StickyHeader')),
  SubNavBar: dynamic(() => import('@/components/ui/SubNavBar')),
  SubscriptionCard: dynamic(
    () => import('@/components/pageBlocks/SubscriptionCard')
  ),
  TeamSection: dynamic(() => import('@/components/pageBlocks/TeamSection')),
  TestimonialCarousel: dynamic(
    () => import('@/components/testimonial/TestimonialCarousel')
  ),
  TextSection: dynamic(() => import('@/components/pageBlocks/TextSection')),
  ThreeColumnInfographic: dynamic(
    () => import('@/components/pageBlocks/ThreeColumnInfographic')
  ),
  ThreeIconSectionWithPicture: dynamic(
    () => import('@/components/pageBlocks/ComponentThreeIconSectionWithPicture')
  ),
  TwoColumnCta: dynamic(() => import('@/components/pageBlocks/TwoColumnCTA')),
  TwoColumnInfographic: dynamic(
    () => import('@/components/pageBlocks/TwoColumnInfographic')
  ),
  TwoColumnTextSection: dynamic(
    () => import('@/components/pageBlocks/TwoColumnTextSection')
  ),
  TypeformEmbed: dynamic(() => import('@/components/pageBlocks/TypeformEmbed')),
  VideoSection: dynamic(
    () => import('@/components/pageBlocks/VideoContentBlock')
  ),
  WhatYouGetModule: dynamic(
    () => import('@/components/pageBlocks/WhatYouGetModule')
  ),
}

export type PageBlockCollectionsProps =
  | TemplateJoinUsPageComponentsCollection
  | TemplateAboutUsPageComponentsCollection
  | TemplateWhyBookWithForaPageComponentsCollection
  | TemplateCareersPageComponentsCollection
  | TemplateDestinationsCollection
  | TemplatePartnersPageComponentsCollection
  | TemplateHomePageComponentsCollection
  | TemplateNewsletterPageComponentsCollection
  | TemplateContactUsPageComponentsCollection
  | TemplateBookingPageComponentsCollection
  | TemplateForaReservePageComponentsCollection
  | TemplateReviewsPageComponentsCollection
  | TemplateForaXPageComponentsCollection

type PageBlockItemProps =
  | TemplateJoinUsPageComponentsItem
  | TemplateAboutUsPageComponentsItem
  | TemplateWhyBookWithForaPageComponentsItem
  | TemplateCareersPageComponentsItem
  | TemplatePartnersPageComponentsItem
  | TemplateHomePageComponentsItem
  | TemplateDestinationsPageComponentsItem
  | TemplateNewsletterPageComponentsItem
  | TemplateContactUsPageComponentsItem
  | TemplateBookingPageComponentsItem
  | TemplateForaReservePageComponentsItem
  | TemplateReviewsPageComponentsItem
  | TemplateForaXPageComponentsItem

const RenderPageBlock = (pageBlock: PageBlockItemProps): JSX.Element | null => {
  const typename = pageBlock.__typename
  const registryKey =
    typename && typename.replace('Pages', '').replace('Component', '')
  const PageBlock = pageBlockRegistry[`${registryKey}`]
  return (PageBlock && <PageBlock {...pageBlock} />) || null
}

const PageBlockRenderer = ({
  items,
}: PageBlockCollectionsProps): JSX.Element => {
  return (
    <SubNavBarContextProvider>
      <StickyHeaderContextProvider>
        <ReviewsContextProvider>
          {items &&
            items.map((pageBlock, i) => {
              return (
                <React.Fragment key={`PageBlock_${pageBlock?.__typename}_${i}`}>
                  {pageBlock && <RenderPageBlock {...pageBlock} />}
                </React.Fragment>
              )
            })}
        </ReviewsContextProvider>
      </StickyHeaderContextProvider>
    </SubNavBarContextProvider>
  )
}

export default PageBlockRenderer
